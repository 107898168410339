import React from "react";
import styled from "styled-components";
import Form from "../Form";
import FormButton from "../FormButton";

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 20px;
  border: 3px solid #a09292;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Balances = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 45px 25px 12px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
const Text = styled.span`
  font-size: 14px;
`;

const StakingForm = ({ network }) => {
  return (
    <Form header={"Staking"}>
      <Balances>
        <Text>Balance: 0</Text>
        <Text>Staking: 0</Text>
      </Balances>
      <StyledInput onInput={() => {}} type="text" />
      <ButtonsWrapper>
        <FormButton onClick={() => {}}>Seed</FormButton>
        <FormButton onClick={() => {}}>Unstake</FormButton>
      </ButtonsWrapper>
    </Form>
  );
};

export default StakingForm;
