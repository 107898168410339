import React, { useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import { getCoinAbi, getContract, getDecimals } from "../CoinSwapper/swap";
import { contractAddress } from "../../constans";
import FormButton from "../FormButton";
import Form from "../Form";

const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 20px;
  border: 3px solid #a09292;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const Balances = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 45px 25px 12px;
`;
const Text = styled.span`
  font-size: 14px;
`;
const Expected = styled(Text)`
  margin: 30px auto 20px;
`;

export async function approve(amount, signer) {
  const token = getContract(
    contractAddress,
    getCoinAbi(contractAddress),
    signer
  );
  const tokenDecimals = await getDecimals(token);
  const amountIn = ethers.utils.parseUnits(amount, tokenDecimals);
  await token.seed(amountIn);
}

const SeedForm = ({ network }) => {
  const [input, setInput] = useState("");
  return (
    <Form header={"Seed"}>
      <Balances>
        <Text>Balance: 0</Text>
        <Text>Seed: 0</Text>
      </Balances>
      <StyledInput onInput={(e) => setInput(e.target.value)} type="text" />
      <Expected>Expected: 0</Expected>
      <FormButton
        onClick={() =>
          approve(input, network.router.current, network.signer.current)
        }
      >
        Seed
      </FormButton>
    </Form>
  );
};

export default SeedForm;
