import React, { useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { connectors, connections } from "./connectors";
import useLocalStorage from "./useLocalStorage";

export default function Web3Provider({ children }) {
  const [wallet] = useLocalStorage("wallet", null);

  useEffect(() => {
    connections.forEach((c) => {
      if (wallet === c.type) {
        c.connector.connectEagerly && c.connector.connectEagerly();
      }
    });
  }, [wallet]);

  return (
    <Web3ReactProvider connectors={connectors} libraryName={"ethers.js"}>
      {children}
    </Web3ReactProvider>
  );
}
