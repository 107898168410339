import React from "react";
import styled from "styled-components";

const FormWrapper = styled.div`
  color: white;
  text-align: center;
  font-family: "Inter";
  font-weight: 200;
  width: 500px;
  height: 400px;
  padding: 40px;
  border-radius: 50px;
  background: rgba(126, 143, 169, 0.25);
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
`;

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h1`
  margin: 0;
  font-size: 40px;
  text-align: center;
`;

const Form = ({ children, header }) => {
  return (
    <FormWrapper>
      <Header>{header}</Header>
      <StyledForm>{children}</StyledForm>
    </FormWrapper>
  );
};

export default Form;
