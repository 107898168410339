import React from "react";
import styled from "styled-components/macro";

const Button = styled.button`
  height: 60px;
  width: 200px;
  color: white;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.25);
  border: 4px solid rgba(255, 255, 255, 0.5);
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`;

const FormButton = ({ children, onClick }) => {
  return <Button onClick={onClick}>{children}</Button>;
};

export default FormButton;
