import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components/macro";
import SeedPage from "./pages/SeedPage";
import StakingPage from "./pages/StakingPage";
import SwapPage from "./pages/SwapPage";
import { GlobalStyle } from "./styles";
import Web3Provider from "./connection/Web3Provider";
import ConnectPage from "./pages/WalletPage";

const AppWrapper = styled.div`
  height: 100vh;
  background: linear-gradient(116.82deg, #13152d 0%, #000000 49.48%, #454545 100%);
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <AppWrapper>
        <Web3Provider>
          <Routes>
            <Route exact path="/" element={<Navigate to={"/wallet"} />} />
            <Route path="/swap" element={<SwapPage />} />
            <Route path="/seed" element={<SeedPage />} />
            <Route path="/staking" element={<StakingPage />} />
            <Route path="/wallet" element={<ConnectPage />} />
          </Routes>
        </Web3Provider>
      </AppWrapper>
    </>
  );
}

export default App;
