import React, { useEffect, useState, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components/macro";
import Form from "../Form";
import InputField from "./SwapInput";
import CoinSelector from "./CoinSelector";
import FormButton from "../FormButton";
import SwitchIcon from "../../assets/icon _loop_.svg";
import { getAmountOut, swapTokens, getBalanceAndSymbol } from "./swap";
import { useMainTokenContract } from "./useContract";

const InputWrapper = styled.div`
  display: flex;
  border: 3px solid #a09292;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
const InputMax = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 10px;
  border-right: 3px solid #a09292;
  border-left: 3px solid #a09292;
  background-color: #c8c7c7;
`;
const SwapWrapper = styled.div`
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 40px;
  width: 100%;
`;
const SwitchButton = styled.div`
  width: 45px;
  padding: 10px;
  display: flex;
  background-color: #a49a9a;
`;
const Balances = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  height: 40px;
`;
const Text = styled.span`
  flex: 1;
`;
const FormButtons = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
`;

const CoinSwapper = () => {
  const { account, provider } = useWeb3React();
  const router = useMainTokenContract();
  let signer;

  if (provider) {
    signer = provider.getSigner(account);
  }

  const [field1Value, setField1Value] = React.useState("");
  const [field2Value, setField2Value] = React.useState("");

  const [coin1, setCoin1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  const [coin2, setCoin2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  const handleChange = useMemo(
    () => ({
      field1: (value) => {
        setField1Value(value);
      },
      field2: (value) => {
        setField2Value(value);
      },
    }),
    []
  );

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    handleChange.field1(field2Value);
  };

  const swap = () => {
    if (!coin1.address || !coin2.address) return;
    swapTokens(coin1.address, coin2.address, field1Value, router, account, signer);
  };

  const getCoin1 = async (address) => {
    if (!account) {
      return;
    }

    getBalanceAndSymbol(account, address, signer).then((data) => {
      setCoin1({
        address: address,
        symbol: data.symbol,
        balance: data.balance,
      });
    });
  };

  const getCoin2 = async (address) => {
    if (!account) {
      return;
    }

    getBalanceAndSymbol(account, address, signer).then((data) => {
      setCoin2({
        address: address,
        symbol: data.symbol,
        balance: data.balance,
      });
    });
  };

  useEffect(() => {
    if (isNaN(parseFloat(field1Value))) {
      handleChange.field2("");
    } else if (parseFloat(field1Value) && coin1.address && coin2.address) {
      getAmountOut(coin1.address, coin2.address, field1Value, router, signer)
        .then((amount) => handleChange.field2(amount.toFixed(7)))
        .catch((e) => {
          console.log(e);
          handleChange.field2("NA");
        });
    } else {
      handleChange.field2("");
    }
  }, [field1Value, coin1.address, coin2.address, router, signer, handleChange]);

  return (
    <Form header={"Exchange"}>
      <SwapWrapper>
        <CoinSelector borderLeft setCoin={(address) => getCoin1(address)}>
          {coin1.symbol || "SELECT"}
        </CoinSelector>
        <SwitchButton onClick={switchFields}>
          <img src={SwitchIcon} alt="" />
        </SwitchButton>
        <CoinSelector borderRight setCoin={(address) => getCoin2(address)}>
          {coin2.symbol || "SELECT"}
        </CoinSelector>
      </SwapWrapper>
      <Balances>
        <Text> Balance: {coin1.balance ? coin1.balance.toFixed(1) : "0"} </Text>
        <Text> Balance: {coin2.balance ? coin2.balance.toFixed(1) : "0"} </Text>
      </Balances>
      <InputWrapper>
        <InputField activeField={true} value={field1Value} onChange={(e) => handleChange.field1(e.target.value)} />
        <InputMax>max</InputMax>
        <InputField activeField={false} value={field2Value} />
      </InputWrapper>
      <FormButtons>
        <FormButton onClick={() => {}}>Approve</FormButton>
        <FormButton onClick={swap}>Swap</FormButton>
      </FormButtons>
    </Form>
  );
};

export default CoinSwapper;
