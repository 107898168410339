import React from "react";
import styled from "styled-components/macro";

const InputFieldCustom = styled.input`
  flex: 1;
  border: none;
  outline: none;
  text-align: center;
  border-width: 0;
  height: 43px;
  box-shadow: none;
  background-color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
`;

const InputField = ({ activeField, value, onChange }) => {
  return (
    <>
      <InputFieldCustom
        value={value}
        onChange={onChange}
        placeholder="0"
        disabled={!activeField}
      />
    </>
  );
};

export default InputField;
