import React, { useState } from "react";
import styled from "styled-components/macro";
import { COINS } from "../../constans";

const StyledCoinButton = styled.button`
  position: relative;
  flex: 1;
  color: #dddddd;
  height: 43px;
  font-size: 20px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: 4px solid #ffffff;
  border-radius: ${({ borderRight, borderLeft }) => `${borderLeft} ${borderRight} ${borderRight} ${borderLeft}`};
  cursor: pointer;
  overflow: visible;
`;

const DropdownInput = styled.input``;

const Dropdown = styled.div`
  display: ${({ showDropdown }) => `${showDropdown ? "block" : "none"}`};
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 10px;
  transform: translate(-50%, 43px);
  width: 200px;
  height: 200px;
  background-color: rgba(126, 143, 169);
`;

const TokenWrapper = styled.div`
  border: solid 1px black;
  margin: 0 15px;
`;

const DropdownList = styled.div``;

const CoinSelector = ({ children, borderRight, borderLeft, setCoin }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = function () {
    if (showDropdown) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
  };

  const handleCoin = function (coin) {
    setCoin(coin.address);
    setShowDropdown(false);
  };

  return (
    <StyledCoinButton
      onClick={handleClick}
      borderRight={borderRight ? "20px" : "0"}
      borderLeft={borderLeft ? "20px" : "0"}
    >
      {children}
      <Dropdown showDropdown={showDropdown} onClick={(e) => e.stopPropagation()}>
        <DropdownInput></DropdownInput>
        <DropdownList>
          {Object.values(COINS).map((coin) => {
            return (
              <TokenWrapper onClick={() => handleCoin(coin)} key={coin.address}>
                {coin.name}
              </TokenWrapper>
            );
          })}
        </DropdownList>
      </Dropdown>
    </StyledCoinButton>
  );
};

export default CoinSelector;
