import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";

export const connectionType = {
  METAMASK: "METAMASK",
  WALLET_CONNECT: "WALLET_CONNECT",
};

export const connectionLabel = {
  [connectionType.METAMASK]: "Metamask",
  [connectionType.WALLET_CONNECT]: "Wallet Connect",
};

export const [metaMaskConnector, metaMaskHooks] = initializeConnector((actions) => new MetaMask({ actions }));

export const metaMaskConnection = {
  connector: metaMaskConnector,
  hooks: metaMaskHooks,
  type: connectionType.METAMASK,
  label: connectionLabel[connectionType.METAMASK],
};

export const [walletConnectConnector, walletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: "719fd987a065f81b3d0da62b921790fe",
        rpc: {
          97: "https://data-seed-prebsc-2-s3.binance.org:8545",
        },
      },
    })
);

export const walletConnectConnection = {
  connector: walletConnectConnector,
  hooks: walletConnectHooks,
  type: connectionType.WALLET_CONNECT,
  label: connectionLabel[connectionType.WALLET_CONNECT],
};

export const connections = [metaMaskConnection, walletConnectConnection];

export const connectors = [
  [metaMaskConnector, metaMaskHooks],
  [walletConnectConnector, walletConnectHooks],
];

export function getConnector(c) {
  if (c instanceof MetaMask || c instanceof WalletConnect) {
    return connections.find((connection) => connection.connector === c);
  } else {
    switch (c) {
      case connectionType.METAMASK:
        return metaMaskConnection;
      case connectionType.WALLET_CONNECT:
        return walletConnectConnection;
      default:
        return {};
    }
  }
}
