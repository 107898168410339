import React from "react";
import Wallet from "../components/Wallet/Wallet";
import FormPage from "./FormPageStyled";

const ConnectPage = () => {
  return (
    <FormPage>
      <Wallet />
    </FormPage>
  );
};

export default ConnectPage;
