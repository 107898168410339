import React, { useCallback } from "react";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import FormButton from "../FormButton";
import { getConnector, connections } from "../../connection/connectors";
import { WalletConnect } from "@web3-react/walletconnect";
import useLocalStorage from "../../connection/useLocalStorage";

const FormWrapper = styled.div`
  color: white;
  text-align: center;
  font-family: "Inter";
  font-weight: 200;
  width: 400px;
  height: 340px;
  padding: 40px;
  border-radius: 50px;
  background: rgba(126, 143, 169, 0.25);
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.5);
`;

const Header = styled.h1`
  margin: 0;
  font-size: 40px;
  text-align: center;
`;

const ConnectButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  margin-top: 50px;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
`;

const ConnectedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 20px;
  margin-top: 10px;
`;

const Wallet = () => {
  // eslint-disable-next-line
  const [_, setWallet] = useLocalStorage("wallet", null);
  const { isActive, connector } = useWeb3React();

  const tryConnection = useCallback(
    async (c) => {
      try {
        setWallet(c.type);
        await c.connector.activate(97);
      } catch (error) {
        setWallet(null);
        throw new Error(`web3-react ${c.type} connection error: ${error}`);
      }
    },
    [setWallet]
  );

  const handleDisconnect = useCallback(() => {
    if (connector instanceof WalletConnect) {
      connector.deactivate();
    }
    setWallet(null);
    connector.resetState();
  }, [connector, setWallet]);

  return (
    <FormWrapper>
      {!isActive ? (
        <>
          <Header>Connect wallet</Header>
          <ConnectButtons>
            {connections.map((c) => {
              const connector = getConnector(c.connector);
              return (
                <FormButton key={Math.random()} onClick={() => tryConnection(c)}>
                  {connector.label}
                </FormButton>
              );
            })}
          </ConnectButtons>
        </>
      ) : (
        <>
          <Header>Connected</Header>
          <ConnectedWrapper>
            <Circle />
            {getConnector(connector).label}
          </ConnectedWrapper>
          <ConnectButtons>
            <FormButton onClick={handleDisconnect}>Disconnect</FormButton>
          </ConnectButtons>
        </>
      )}
    </FormWrapper>
  );
};

export default Wallet;
