import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.svg";

const Simg = styled.img`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const NavbarStyled = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(126, 143, 169, 0.25);
  border-bottom: 1px solid #000000;
  box-shadow: 0px 10px 10px rgba(126, 143, 169, 0.25);
`;

const StyledLink = styled(Link)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-decoration: none;
  text-align: center;
  width: 144px;
  color: #dddddd;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(24, 190, 30, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const Navbar = () => {
  return (
    <NavbarStyled>
      <Simg src={logo} alt="" />
      <StyledLink to="/swap">swap</StyledLink>
      <StyledLink to="/staking">staking</StyledLink>
      <StyledLink to="/seed">seed</StyledLink>
      <StyledLink to="/wallet">wallet</StyledLink>
    </NavbarStyled>
  );
};

export default Navbar;
