import { ethers, Contract } from "ethers";
import { COINS, ABIS } from "../../constans";

export function getContract(address, abi, signer) {
  return new Contract(address, abi, signer);
}

export function getCoin(address) {
  return Object.keys(COINS).find((value) => {
    return address === COINS[value].address;
  });
}

export function getCoinAbi(address) {
  return ABIS[getCoin(address)];
}

export async function getBalanceAndSymbol(accountAddress, address, signer) {
  try {
    const token = getContract(address, getCoinAbi(address), signer);
    const balanceRaw = await token.balanceOf(accountAddress);
    const symbol = await token.symbol();

    return {
      balance: Number(ethers.utils.formatUnits(balanceRaw, 18)),
      symbol: symbol,
    };
  } catch (error) {
    console.log("The getBalanceAndSymbol function had an error!");
    console.log(error);
    return false;
  }
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("No tokenDecimals function for this token, set to 0");
      return 0;
    });
  return decimals;
}

export async function getAmountOut(address1, address2, amountIn, routerContract, signer) {
  try {
    const token1 = getContract(address1, getCoinAbi(address1), signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = getContract(address2, getCoinAbi(address2), signer);
    const token2Decimals = await getDecimals(token2);

    const values_out = await routerContract.getAmountsOut(ethers.utils.parseUnits(String(amountIn), token1Decimals), [
      address1,
      address2,
    ]);

    const amount_out = values_out[1] * 10 ** -token2Decimals;
    console.log("amount out: ", amount_out);
    return Number(amount_out);
  } catch {
    return false;
  }
}

export async function swapTokens(address1, address2, amount, routerContract, accountAddress, signer) {
  console.log("try");
  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const token1 = getContract(address1, getCoinAbi(address1), signer);
  const tokenDecimals = await getDecimals(token1);

  const amountIn = ethers.utils.parseUnits(amount, tokenDecimals);
  const slippage = 0.85;
  const slipAmount = ethers.utils.parseUnits(String(+amount * slippage), tokenDecimals);
  const amountOut = await routerContract.getAmountsOut(slipAmount, tokens);

  await token1.approve(routerContract.address, amountIn);
  await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
    amountIn,
    amountOut[1],
    tokens,
    accountAddress,
    deadline,
    {
      gasLimit: 500000,
    }
  );
}
