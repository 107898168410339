import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  };

  body {
    padding: 0;
    margin: 0;
  };

  @font-face {
    font-family: 'Inter', sans-serif;
  }
`