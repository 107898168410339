import CONTRACT from './abis/CONTRACT.json'
import ROUTER from './abis/PCS.json'
import BUSD from './abis/BUSD.json'

export const routerAddress = '0xCc7aDc94F3D80127849D2b41b6439b7CF1eB4Ae0';
export const contractAddress = "0x4366caA9Ff35C0297d3c61C44a615833AADB4Eee";
export const busdAddress = "0xb2446845A4E4e2658AC1155247FefB211e356b98";

export const COINS = {
  BUSD: {
    name: "BUSD",
    abbr: "BUSD",
    address: busdAddress,
  },

  TKN: {
    name: "MainToken",
    abbr: "TKN",
    address: contractAddress,
  }
}

export const ABIS = {
  BUSD: BUSD.abiBUSD,
  TKN: CONTRACT.abi,
  ROUTER: ROUTER.abiPCS,
}