import React from "react";
import FormPage from "./FormPageStyled";
import SeedForm from "../components/SeedForm/SeedForm";

const SeedPage = () => {
  return (
    <FormPage>
      <SeedForm />
    </FormPage>
  );
};

export default SeedPage;
