import React from "react";
import FormPage from "./FormPageStyled";
import StakingForm from "../components/StakingForm/StakingForm";

const StakingPage = () => {
  return (
    <FormPage>
      <StakingForm />
    </FormPage>
  );
};

export default StakingPage;
