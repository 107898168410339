import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { utils, constants } from "ethers";
import { ABIS, routerAddress } from "../../constans";

function getSigner(provider, account) {
  return provider.getSigner(account);
}

function getProviderOrSigner(provider, account) {
  return account ? getSigner(provider, account) : provider;
}

export function getContract(address, ABI, provider, account) {
  if (!utils.isAddress(address) || address === constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account));
}

export function useContract(address, ABI) {
  const { provider, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !provider || !chainId) return null;
    try {
      return getContract(address, ABI, provider, account);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, provider, chainId, account]);
}

export function useMainTokenContract() {
  return useContract(routerAddress, ABIS.ROUTER);
}
