import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";

const FormPageStyled = styled.div`
  height: 100%;
`;
const PageContent = styled.div`
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormPage = (props) => {
  return (
    <FormPageStyled>
      <Navbar />
      <PageContent>{props.children}</PageContent>
    </FormPageStyled>
  );
};

export default FormPage;
